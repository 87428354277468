import Img from "gatsby-image";
import React from "react";

import { FluidObject } from "gatsby-image";
import { Helmet } from "react-helmet";

import { FacebookShareButton } from "react-share";
import { Fragment } from "react";
import { LinkedinShareButton } from "react-share";
import { TwitterShareButton } from "react-share";
import { WhatsappShareButton } from "react-share";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { config } from "@fortawesome/fontawesome-svg-core";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { graphql } from "gatsby";

import parse from "html-react-parser";

import Breadcrumbs from "@Js/Component/Breadcrumbs/Breadcrumbs";

import classNames from "@Css/Component/Template/Event.module.scss";

import "@Css/Wordpress/EntryContent.module.scss";
import "@Css/Wordpress/WpBlockButtons.module.scss";
import "@Css/Wordpress/WpBlockColumns.module.scss";
import "@Css/Wordpress/WpBlockCover.module.scss";
import "@Css/Wordpress/WpBlockGallery.module.scss";
import "@Css/Wordpress/WpBlockImage.module.scss";
import "@Css/Wordpress/WpBlockLatestPosts.module.scss";
import "@Css/Wordpress/WpBlockQuote.module.scss";
import "@Css/Wordpress/WpBlockSeparator.module.scss";
import "@Css/Wordpress/WpBlockTable.module.scss";

config.autoAddCss = false;

export type EventProps = Readonly<{
    data: {
        wordpressWpEvent: {
            acf: {
                event_datetime: string;
                event_location: string;
            };
            content: string;
            featured_media: {
                localFile: {
                    childImageSharp: {
                        fluid: FluidObject;
                    };
                };
            };
            slug: string;
            title: string;
            yoast_head: string;
        };
    };
}>;

export const postQuery = graphql`
    query($id: String!) {
        wordpressWpEvent(id: { eq: $id }) {
            acf {
                event_datetime(formatString: "D MMM YYYY \\o\\m HH:mm", locale: "NL-nl")
                event_location
            }
            content
            featured_media {
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
            slug
            title
            yoast_head
        }
    }
`;

const Event = (props: EventProps) => {
    const event = props.data.wordpressWpEvent;

    return (
        <Fragment>
            <Helmet>
                { parse(event.yoast_head) }
            </Helmet>
            <Breadcrumbs breadcrumbs={ [
                { text: "Home", url: "/" },
                { text: "Agenda", url: "/agenda" },
                { text: event.title, url: "/" + event.slug }]
            } />
            <main>
                <article>
                    <header className={ classNames.header }>
                        <Img className={ classNames.featuredImage } fluid={ event.featured_media.localFile.childImageSharp.fluid } />
                        <h1 className={ classNames.title }>{ parse(event.title) }</h1>
                    </header>
                    <div className={ classNames.info }>
                        <time className={ classNames.datetime }>{ event.acf.event_datetime }</time>
                        <time className={ classNames.location }>{ event.acf.event_location }</time>
                        <div className={ classNames.share }>
                            <span>Delen: </span>
                            <FacebookShareButton url={ typeof window == "undefined" ? "" : window.location.href }>
                                <FontAwesomeIcon icon={ faFacebookF as unknown as "facebook-f" } />
                            </FacebookShareButton>
                            <TwitterShareButton url={ typeof window == "undefined" ? "" : window.location.href }>
                                <FontAwesomeIcon icon={ faTwitter as unknown as "twitter" } />
                            </TwitterShareButton>
                            <LinkedinShareButton url={ typeof window == "undefined" ? "" : window.location.href }>
                                <FontAwesomeIcon icon={ faLinkedinIn as unknown as "linkedin-in" } />
                            </LinkedinShareButton>
                            <WhatsappShareButton url={ typeof window == "undefined" ? "" : window.location.href }>
                                <FontAwesomeIcon icon={ faWhatsapp as unknown as "whatsapp" } />
                            </WhatsappShareButton>
                        </div>
                    </div>
                    <div className="entry-content">
                        { parse(event.content) }
                    </div>
                </article>
            </main>
        </Fragment>
    );
};

export default Event;
